exports.fullPrayers = {
    "إذا استيقظ من منامه":
        [{
            "text": `الحَمْدُ لِلَّهِ الَّذي أحْيانا بَعْدَ ما أماتَنا وإلَيْهِ النشُورُ‏.

الحمدُ لله الذي عافاني في جَسَدي وردّ علي روحي، وأَذِنَ لي بِذِكْرِه.`,
            "source": "البخاري 6321. مسلم 2711. الترمذي 3421.",
            "count": "1",
            "order": "1",
            "categoryId": "-MPAzasxg4nIT682ZHSR"
        },], "إذا استيقظ من الليل للتَهَجّد":
        [{
            "text": `اللَّهُمَّ لَكَ الحَمْدُ، أنْتَ قَيِّمُ السَّماوَاتِ والأرْضِ وَمَنْ فِيهنَّ، وَلَكَ الحَمْدُ، لَكَ مُلْكُ السَّماواتِ والأرضِ وَمَن فيهن، وَلَكَ الحَمْدُ، أنْت نُورُ السَّماواتِ والأرْضِ، ولكَ الحَمدُ، أنْتَ مَلِكُ السَّماواتِ والأرْضِ، ولكَ الحَمدُ، أنْتَ الحَقُّ وَوَعْدُكَ الحَقّ، ولِقَاؤُكَ حَقٌّ، وَقَوْلُكَ حَقٌّ، والجَنَّةُ حَقٌّ، والنَّارُ حَقٌّ، والنّبيّونَ حَقٌّ، ومُحَمَّدٌ صَلى اللَّهُ عَلَيْهِ وَسَلَّم حَقٌّ، والسَّاعَةُ حَقٌّ، اللَّهُمَّ لَكَ أسْلَمْتُ، وَبِكَ آمَنْتُ، وَعَلَيْكَ تَوَكَّلْتُ، وَإِلَيْكَ أَنَبْتُ، وبِكَ خاصَمْتُ، وَإلَيْكَ حاكَمْتُ، فاغْفِرْ لي ما قَدَّمْتُ وَمَا أخَّرْتُ، وَمَا أَسْرَرْتُ وَمَا أعْلَنْتُ، أنْتَ المُقَدِّمُ وأنْتَ المُؤَخِّرُ، لا إلهَ إِلاَّ أنتَ‏ ‏وَ لا حَوْلَ وَ لا قُوَّة إلاَّ باللَّهِ.`,
            "source": "البخاري 1120",
            "count": "1",
            "order": "1",
            "categoryId": "-MPB-n0S2hf8lJ5A7r2D"
        }, {
            "text": `الله أَكْبَر`,
            "source": "أبو داود 5085",
            "count": "10",
            "order": "2",
            "categoryId": "-MPB-n0S2hf8lJ5A7r2D"
        }, {
            "text": `الحَمْدُ لله`,
            "source": "أبو داود 5085",
            "count": "10",
            "order": "3",
            "categoryId": "-MPB-n0S2hf8lJ5A7r2D"
        }, {
            "text": `سُبْحانَ الله وَبحَمْدِهِ`,
            "source": "أبو داود 5085",
            "count": "10",
            "order": "4",
            "categoryId": "-MPB-n0S2hf8lJ5A7r2D"
        }, {
            "text": `سُبْحانَ المَلِكُ القُدّوس`,
            "source": "أبو داود 5085",
            "count": "10",
            "order": "5",
            "categoryId": "-MPB-n0S2hf8lJ5A7r2D"
        }, {
            "text": `اسْتَغفِرُ الله`,
            "source": "أبو داود 5085",
            "count": "10",
            "order": "6",
            "categoryId": "-MPB-n0S2hf8lJ5A7r2D"
        }, {
            "text": `
اللّهُمَ إِنّي أَعوذُ بِكَ مِن ضيقِ الدّنيا وَضيقِ يَوْمِ القِيامَةِ`,
            "source": "أبو داود 5085",
            "count": "10",
            "order": "7",
            "categoryId": "-MPB-n0S2hf8lJ5A7r2D"
        },], "إذا طلعت الشمس":
        [{
            "text": `الحَمدُ للهِ الذي وَهَبَ لنا هذا اليَومّ وأَقالَنا فيه مِن عَثَراتِنا.`,
            "source": "النووي في الأذكار 208/2",
            "count": "1",
            "order": "1",
            "categoryId": "-MPLnGc6T4I-NObNeotK"
        },], "إذا خلع ثوبه":
        [{
            "text": `بِسْمِ اللَّهِ الذي لا إِلهَ إلاَّ هُو.`,
            "source": "الطبراني في الأوسط 7066. النووي في الأذكار 43/1",
            "count": "1",
            "order": "1",
            "categoryId": "-MPB00rmD0FeZrEbolyE"
        },], "إذا أراد دخول الخلاء":
        [{
            "text": `اللَّهُمَّ إني أعُوذُ بِكَ مِنَ الخُبْثِ وَالخَبَائث.`,
            "source": "البخاري 142. مسلم 375. النسائي 19. الترمذي 6.",
            "count": "1",
            "order": "1",
            "categoryId": "-MPB08d-ad5cwCo8eKgw"
        },],"إذا انتهى من قضاء حاجته":
        [{
                  "text":`الحَمْدُ لِلَّه الَّذي أذَاقَنِي لَذَّتَهُ، وأبْقَى فِيَّ قُوَّتَهُ، وَدَفَعَ عَنِّي أَذَاهُ.`,
                  "source":"النووي في الأذكار 60/1",
                  "count":"1",
                  "order":"1",
                  "categoryId":"-MPP5RsnE0RYC7RGffX2"
                  },], "قبل الوضوء أو الاغتسال أو التيمم":
        [{
            "text": `بِاسْمِ اللَّهِ.`,
            "source": "السنن الكبرى للبيهقي 191. النسائي 84.",
            "count": "1",
            "order": "1",
            "categoryId": "-MPB0LGSaFm02vcr3Suw"
        },], "بعد الوضوء أو الاغتسال أو التيمم":
        [{
            "text": `اللّهمّ اجعَلْني مِنَ التوّابين، واجْعَلْني مِنَ المتَطَهّرين، سُبحانَك اللّهُم وَبِحَمْدِك، أَشْهَدُ أَن لا إِلهَ إِلّا أَنْتَ، أَسْتَغْفِرُكَ وَأَتوبُ إلَيْك.`,
            "source": "النووي في الأذكار 62/2",
            "count": "1",
            "order": "2",
            "categoryId": "-MPB0TxBE8MxM_6gPQJ2"
        }, ], "إذا خرج من الخلاء":
        [{
            "text": `غُفْرَانَكَ.`,
            "source": "الترمذي 7. أبو داود 30. ابن ماجة 300. الدارمي 680",
            "count": "1",
            "order": "1",
            "categoryId": "-MPB0Y13Mn-dsA8WUh5a"
        },], "إذا لبس ثوبه":
        [{
            "text": `بِسْمِ الله، الحَمْدُ للّه الذي كَساني هَذَا الثَّوْبَ وَرَزَقنيهِ مِنْ غَيْرِ حَوْلٍ مِنِّي وَلا قُوَّة.`,
            "source": "أبو داود 4023",
            "count": "1",
            "order": "1",
            "categoryId": "-MPEnTRi8ERmGgKaV580"
        },], "إذا لبس ثوباً جديداً أو نعلاً وما أشبهه":
        [{
            "text": `بسم الله. اللَّهُمَّ لَكَ الْحَمْدُ، أَنْتَ كَسَوْتَنِيهِ، أَسْأَلُكَ مِنْ خَيْرِهِ وَخَيْرِ مَا صُنِعَ لَهُ، وَأَعُوذُ بِكَ مِنْ شَرِّهِ وَشَرِّ مَا صُنِعَ لَهُ.`,
            "source": "أبو داود 4020",
            "count": "1",
            "order": "1",
            "categoryId": "-MPEnmGTbADwmm1KnWfo"
        },], "إذا نظر في المرآة":
        [{
            "text": `الحَمْدُ لله الذي سَوّى خَلْقي فعدّله، وَكَرّمَ صورةَ وَجْهي فَحَسّنَها، وَجَعَلَني مِنَ المسْلِمين. اللّهُمَ كَما حَسّنْتَ خَلقي فحسّن خُلُقي.`,
            "source": "النووي في الأذكار 783/1. 784/1",
            "count": "1",
            "order": "1",
            "categoryId": "-MPPIA_t0T45vP6fGR__"
        },], "إذا خرج من بيته":
        [{
            "text": `يرفع رأسه إلى السماء ثم يقول:
باسْمِ اللَّهِ، تَوَكَّلْتُ على اللَّهِ، وَلاَ حَوْلَ وَلاَ قُوَّةَ إِلاَّ باللَّه، اللَّهُمَّ إني أَعُوذُ بِكَ أنْ أضِلَّ أَوْ أُضَلَّ، أوْ أزِلَّ أَوْ أُزَلَّ، أَوْ أظْلِمَ أَوْ أُظْلَمَ، أوْ أجْهَلَ أَوْ يُجْهَلَ عَليَّ.
`,
            "source": "أبو داود 5094",
            "count": "1",
            "order": "1",
            "categoryId": "-MPEpEdsSKDMpYwIdFUh"
        },], "إذا خرج من بيته وقد تعسّرت عليه معيشته":
        [{
            "text": `بِسْمِ اللَّهِ على نَفْسِي ومَالي ودِينِي، اللَّهُمَّ رضّنِي بِقَضائِك، وباركْ لي فِيما قُدّرَ لي حتَّى لا أُحِبَّ تَعْجِيلَ ما أخَّرْتَ ولا تأخيرَ ما عَجَّلْتَ.`,
            "source": "النووي في الأذكار 327/1",
            "count": "1",
            "order": "1",
            "categoryId": "-MPPJXSUYgB_Ad5DJVpa"
        },], "إذا ركب دابّته أو سيّارته":
        [{
            "text": `إذا همّ بالركوب يبدأ بقول:
بِاسْمِ اللّه.

وإذا ركب واستقر يقول:
سبحان الَّذي سَخَّرَ لَنا هَذَا وَمَا كُنَّا لَهُ مُقْرِنِينَ وَإِنَّا إلى رَبِّنا لَمُنْقَلِبُونَ‏.
`,
            "source": "أبو داود 2602. الترمذي 3446",
            "count": "1",
            "order": "1",
            "categoryId": "-MPPMeQorCGZ4sMSSE3Q"
        }, {
            "text": `الحمد لله`,
            "source": "أبو داود 2602. الترمذي 3446",
            "count": "3",
            "order": "2",
            "categoryId": "-MPPMeQorCGZ4sMSSE3Q"
        }, {
            "text": `الله أكبر`,
            "source": "أبو داود 2602. الترمذي 3446",
            "count": "3",
            "order": "3",
            "categoryId": "-MPPMeQorCGZ4sMSSE3Q"
        }, {
            "text": `سُبْحانَك إني ظَلَمْتُ نَفْسِي فاغْفِرْ لي، إنَّهُ لا يَغْفِرُ الذُّنُوبَ إِلاَّ أنْتَ.

ثم يضحك بابتسامة خفيفة.`,
            "source": "أبو داود 2602. الترمذي 3446",
            "count": "1",
            "order": "4",
            "categoryId": "-MPPMeQorCGZ4sMSSE3Q"
        },], "إذا توجّه إلى المسجد":
        [{
            "text": `اللَّهُمَّ اجْعَلْ في قَلْبِي نُوراً، وفي لِسانِي نُوراً، وَاجْعَلْ في سَمْعِي نُوراً، وَاجْعَلْ في بَصَري نُوراً، وَاجْعَلْ مِنْ خَلْفِي نُوراً، وَمِنْ أمامي نُوراً، وَاجْعَلْ مِنْ فَوْقي نُوراً وَمِنْ تَحْتِي نُوراً، اللَّهُمَّ أعْطِني نُوراً‏.`,
            "source": "أبو داود 1353",
            "count": "1",
            "order": "1",
            "categoryId": "-MPPP1U-BfewcfLlijPt"
        },], "عند دخول المسجد":
        [{
            "text": `أعُوذُ باللّه العَظِيم، وَبِوَجهِهِ الكَريم، وسُلْطانِهِ القَديم، من الشِّيْطانِ الرَّجِيم.
`,
            "source": "أبو داود 466",
            "count": "1",
            "order": "1",
            "categoryId": "-MPPRhewc5pfgws43Xd4"
        }, {
            "text": `اللهُمّ صلّ وَسَلّم عَلى محمَّد وَعَلى آل محمّد، اللّهُمّ اغْفِرْ لي ذُنوبي وافْتَح لي أَبْوابَ رَحمَتِك، بِاسْمِ اللّه (ويقدّم رجله اليمنى).

ويستحب أن ينوي الاعتكاف بمجرد دخول المسجد فيقول: نَوَيتُ الإعْتِكافَ لِوَجْهِكَ الكَريم.`,
            "source": "الترمذي 314. النووي في الأذكار 69/1",
            "count": "1",
            "order": "2",
            "categoryId": "-MPPRhewc5pfgws43Xd4"
        },], "إذا سمع الأذان":
        [{
            "text": `بعد ترديد الأذان في أذان المغرب يقول:

اللَّهُمَّ هَذَا إقْبالُ لَيْلِكَ وَإِدْبَارُ نَهارِكَ وأصْوَاتُ دُعاتِكَ فاغْفِرْ لي.`,
            "source": "أبو داود 530",
            "count": "1",
            "order": "1",
            "categoryId": "-MPa21ypzCeacCZWdqk_"
        }, {
            "text": `يردد مثل المؤذن إلا في "حي على الصلاة وحي على الفلاح" فيقول: لا حَوْلَ وَلا قُوّة إلا بِالله.

`,
            "source": "البخاري 611. مسلم 385",
            "count": "1",
            "order": "1",
            "categoryId": "-MPa21ypzCeacCZWdqk_"
        },], "بعد الانتهاء من الأذان":
        [{
            "text": `اللَّهُمَّ رَبِّ هَذهِ الدَّعْوَةِ التَّامَّةِ وَالصَّلاةِ القائِمةِ، آتِ مُحَمَّداً الوَسِيلَةَ والفَضِيلَةَ، وابْعَثْهُ مَقاماً محموداً الذي وَعَدْتَهُ.`,
            "source": "البخاري 589. الترمذي 211. أحمد 14209",
            "count": "1",
            "order": "1",
            "categoryId": "-MPa8fFmJwbIOzkiRKCl"
        },], "عند سماع الإقامة للصلاة":
        [{
            "text": `يردد مثل المقيم (كما يردد في الأذان)، وعند سماعه "قد قامت الصلاة قد قامت الصلاة" يقول: أَقامَها وَأَدامَها.`,
            "source": "أبو داود 528",
            "count": "1",
            "order": "1",
            "categoryId": "-MPaAaqpEx_5XZBodJIL"
        },], "إذا قام إلى الصلاة":
        [{
            "text": `أستغفر الله`,
            "source": "النووي في الأذكار 99/1",
            "count": "10",
            "order": "1",
            "categoryId": "-MPaEZzSA3GvMpWxExnq"
        }, {
            "text": `الله أكبر`,
            "source": "النووي في الأذكار 99/1",
            "count": "10",
            "order": "1",
            "categoryId": "-MPaEZzSA3GvMpWxExnq"
        }, {
            "text": `الحمد لله`,
            "source": "النووي في الأذكار 99/1",
            "count": "10",
            "order": "1",
            "categoryId": "-MPaEZzSA3GvMpWxExnq"
        }, {
            "text": `لا إله إلا الله`,
            "source": "النووي في الأذكار 99/1",
            "count": "10",
            "order": "1",
            "categoryId": "-MPaEZzSA3GvMpWxExnq"
        }, {
            "text": `سبحان الله`,
            "source": "النووي في الأذكار 99/1",
            "count": "10",
            "order": "1",
            "categoryId": "-MPaEZzSA3GvMpWxExnq"
        },], "إذا انتهى إلى الصف للصلاة":
        [{
            "text": `اللهُمّ آتِني أَفْضَل ما تُؤتي عِبادَكَ الصّالحين.`,
            "source": "النووي في الأذكار 98/1",
            "count": "10",
            "order": "1",
            "categoryId": "-MPaHK0IQwwz3UwTobgd"
        },], "ما يقوله بعد تكبيرة الإحرام":
        [{
            "text": `اللَّهُ أكْبَرُ كَبِيراً، وَالحَمْدُ لِلَّهِ كَثِيراً، وَسُبْحَانَ اللّه بُكْرَةً وَأصِيلاً، وَجَّهْتُ وَجْهِيَ لِلَّذِي فَطَرَ السَّمَوَاتِ وَالأَرْضَ حَنِيفاً مُسْلِماً وما أنا من المُشْرِكِينَ، إنَّ صَلاتِي وَنُسُكِي وَمَحْيايَ وَمَمَاتِي لِلَّهِ رَبّ العالَمِينَ لا شَرِيكَ لَهُ وَبِذَلِكَ أُمِرْتُ وأنَا مِنَ المُسْلِمينَ، اللَّهُمَّ أنْتَ المَلكُ لا إلهَ إِلاَّ أنْتَ، أَنْتَ رَبِّي وأنا عَبْدُكَ، ظَلَمْتُ نَفْسِي واعْتَرَفْتُ بِذَنْبِي، فاغْفِرْ لي ذُنُوبِي جَمِيعاً، فإنَّهُ لاَ يَغْفِرُ الذُّنُوبَ إِلاَّ أنْتَ، وَاهْدِني لأحْسَنِ الأخْلاقِ لا يَهْدِي لأحْسَنها إلاَّ أَنْتَ وَاصْرِفْ عَنِّي سَيِّئَها لا يَصْرِفُ سَيِّئَها إِلاَّ أَنْتَ، لَبَّيْكَ وَسَعْدَيْكَ والخَيْرُ كُلُّهُ في يَدَيْكَ، وَالشَّرُّ لَيْسَ إِلَيْكَ، أنا بِكَ وَإِلَيْكَ، تَبارَكْتَ وَتعالَيْتَ، أسْتَغْفِرُكَ وأتُوبُ إِلَيْكَ‏. اللَّهُمَّ باعِد بَيْني وبَيْنَ خَطايايَ كما بَاعَدْتَ بَيْنَ المَشْرِقِ وَالمَغْرِبِ، اللَّهُمَّ نَقِّنِي مِنْ خَطايايَ كما يُنَقّى الثَّوْبُ الأبْيَضُ مِنَ الدَّنَسِ، اللَّهُمَّ اغْسِلْنِي مِنْ خَطايايَ بالثَّلْجِ وَالمَاءِ وَالبَرَدِ‏.‏`,
            "source": "مسلم 771. النووي في الأذكار باب ما يقوله بعد تكبيرة الإحرام",
            "count": "1",
            "order": "1",
            "categoryId": "-MPoN5AGZClGoA1xxWpw"
        }, {
            "text": `المختصر:

وَجَّهْتُ وَجْهِيَ لِلَّذِي فَطَرَ السَّمَوَاتِ وَالأَرْضَ حَنِيفاً مُسْلِماً وما أنا من المشْرِكِينَ، إنَّ صَلاتِي وَنُسُكِي وَمَحْيايَ وَمَمَاتِي لِلَّهِ رَبّ العالَمِينَ لا شَرِيكَ لَهُ وَبِذَلِكَ أُمِرْتُ وأنَا مِنَ المسْلِمينَ.`,
            "source": "مسلم 771",
            "count": "1",
            "order": "2",
            "categoryId": "-MPoN5AGZClGoA1xxWpw"
        },], "أذكار الركوع":
        [{
            "text": `سُبْحانَ رَبيَ العَظِيمِ (ثلاثاً للتمام)`,
            "source": "مسلم 772. النسائي 638. الترمذي 261",
            "count": "3",
            "order": "1",
            "categoryId": "-MPoVIboK0DpDyfjSQtg"
        }, {
            "text": `اللهمَّ لكَ رَكَعْتُ، وَبِكَ آمَنْتُ، ولَكَ أسْلَمْتُ، خَشَعَ لَكَ سَمْعِي وَبَصَرِي ومُخِّي وَعَظْمِي وَعَصبِي‏.`,
            "source": "مسلم 771. النسائي 641. الترمذي 3421. أبو داود 760",
            "count": "1",
            "order": "2",
            "categoryId": "-MPoVIboK0DpDyfjSQtg"
        }, {
            "text": `سُبُّوحٌ قُدُّوسٌ رَبُّ المَلائِكَةِ والرُّوحِ.`,
            "source": "النسائي 640",
            "count": "1",
            "order": "3",
            "categoryId": "-MPoVIboK0DpDyfjSQtg"
        },], "إذا رفع رأسه من الركوع وحال الاعتدال":
        [{
            "text": `سَمِعَ اللَّهُ لِمَنْ حَمِدَهُ.`,
            "source": "البخاري 1046. مسلم 702",
            "count": "1",
            "order": "1",
            "categoryId": "-MQYD3zbHpVJ3Spg9qi8"
        }, {
            "text": `في حال اعتداله بعد قوله سمع الله لمن حمده:

رَبَّنَا ولَكَ الحَمْدُ حمداً كثيراً طيباً مباركاً فيه، مِلْءُ السَّمَوَاتِ والأرْضِ، ومِلْءُ ما شِئْتَ مِن شيءٍ بَعْدُ، أهْلَ الثَّنَاءِ والْمَجْدِ، أحَقُّ ما قالَ العَبْدُ، وكُلُّنَا لكَ عَبْدٌ: اللَّهُمَّ لا مَانِعَ لِما أعْطَيْتَ، ولَا مُعْطِيَ لِما مَنَعْتَ، ولَا يَنْفَعُ ذَا الجَدِّ مِنْكَ الجَدُّ.`,
            "source": "البخاري 799. مسلم 477",
            "count": "1",
            "order": "2",
            "categoryId": "-MQYD3zbHpVJ3Spg9qi8"
        },], "أذكار السجود":
        [{
            "text": `سُبحان ربي الأعلى`,
            "source": "مسلم 772",
            "count": "3",
            "order": "1",
            "categoryId": "-MRK06z_nznKKAVEqvQx"
        }, {
            "text": `اللَّهُمَّ لَكَ سَجَدْتُ، وَبِكَ آمَنْتُ، ولَكَ أسْلَمْتُ، سَجَدَ وَجْهِي للَّذي خَلَقَهُ وَصَوَّرَهُ، وَشَقّ سَمْعَهُ وَبَصَرَهُ، تبارَكَ اللَّهُ أحْسَنُ الخالِقين.`,
            "source": "مسلم 771. أبو داود 760. الترمذي 3423",
            "count": "1",
            "order": "2",
            "categoryId": "-MRK06z_nznKKAVEqvQx"
        }, {
            "text": `سُبُّوحٌ قُدُّوس، رَبُّ المَلائِكَةِ والرُّوحِ.`,
            "source": "مسلم 487",
            "count": "1",
            "order": "3",
            "categoryId": "-MRK06z_nznKKAVEqvQx"
        }, {
            "text": `اللَّهُمَّ أعُوذُ بِرضَاكَ مِنْ سَخطِكَ، وبِمُعافاتِكَ مِنْ عُقُوبَتِكُ، وأعُوذُ بِكَ مِنْكَ، لا أُحْصِي ثَناءً عَلَيْكَ، أنْتَ كمَا أثْنَيْتَ على نَفْسِكَ. `,
            "source": "مسلم 486. أبو داود 879",
            "count": "1",
            "order": "4",
            "categoryId": "-MRK06z_nznKKAVEqvQx"
        }, {
            "text": `اللَّهُمَّ اغْفِرْ لي ذَنْبِي كُلَّهُ، دِقَّهُ وَجِلَّهُ وأوّلَهُ وآخِرَهُ وَعَلانِيَتَهُ وَسِرَّه.`,
            "source": "أبو داود 878",
            "count": "1",
            "order": "5",
            "categoryId": "-MRK06z_nznKKAVEqvQx"
        }, {
            "text": `ويجوز الاجتهاد في الدعاء.`,
            "source": "مسلم 479",
            "count": "1",
            "order": "6",
            "categoryId": "-MRK06z_nznKKAVEqvQx"
        },], "أذكار سجود التلاوة":
        [{
            "text": `أذكار سجود التلاوة هي أذكار السجود في الصلاة، ويضاف إليها:

اللَّهُمَّ اجْعَلْها لي عِنْدَكَ ذُخْراً، وأعْظِمْ لي بِهَا أجْراً، وَضَعْ عَنِّي بِها وِزْراً، وَتَقَبَّلْها مِنِّي كما تَقَبَّلْتَها مِنْ دَاوُدَ عَلَيْهِ السَّلامُ.
`,
            "source": "الترمذي 3424",
            "count": "1",
            "order": "1",
            "categoryId": "-MRKBb2tdS_g9XBTh_i9"
        },], "بين السجدتين":
        [{
            "text": `رَبّ اغْفِرْ لي، رَبّ اغْفِرْ لي`,
            "source": "النووي في الأذكار 1/128",
            "count": "1",
            "order": "1",
            "categoryId": "-MRKJPGOHswFQgNtoI3a"
        }, {
            "text": `اللهُمّ اغْفِرْ لي وارْحَمْنِي واجْبُرْنِي وَارْفَعْنِي وَارْزُقْنِي وَاهْدِني‏ وَعَافِني`,
            "source": "أبو داود 850. الترمذي 284",
            "count": "1",
            "order": "2",
            "categoryId": "-MRKJPGOHswFQgNtoI3a"
        },], "الدعاء بعد التشهّدِ الأخير في الصَّلاة":
        [{
            "text": `ويجوز الدعاء بما تشاء.`,
            "source": "النسائي 1234",
            "count": "1",
            "order": "1",
            "categoryId": "-MRLVzOK3BEWgMvCtes5"
        }, {
            "text": `اللَّهُمَّ إِنِّي أَعُوذُ بِكَ مِنَ الْمَأْثَمِ وَالْمَغْرَمِ. اللَّهُمَّ إِنِّي ظَلَمْتُ نَفْسِي ظُلْمًا كَثِيرًا وَلا يَغْفِرُ الذُّنُوبَ إِلا أَنْتَ فَاغْفِرْ لِي مَغْفِرَةً مِنْ عِنْدِكَ وَارْحَمْنِي إِنَّك أَنْتَ الْغَفُورُ الرَّحِيمُ.`,
            "source": "البخاري 832-834",
            "count": "1",
            "order": "1",
            "categoryId": "-MRLVzOK3BEWgMvCtes5"
        }, {
            "text": `اللَّهُمَّ إِنِّي أَعُوذُ بِكَ مِنْ عَذَابِ جَهَنَّمَ وَمِنْ عَذَابِ الْقَبْرِ وَمِنْ فِتْنَةِ الْمَحْيَا وَالْمَمَاتِ وَمِنْ شَرِّ فِتْنَةِ الْمَسِيحِ الدَّجَّال. اللَّهمَّ اغفِر لي ما قدَّمتُ وما أخَّرتُ وما أسررتُ وما أعلنتُ وما أسرفتُ وما أنتَ أعلمُ بهِ منِّي أنتَ المقدِّمُ وأنتَ المؤخِّرُ لا إلهَ إلَّا أنتَ.`,
            "source": "مسلم 588-771",
            "count": "1",
            "order": "1",
            "categoryId": "-MRLVzOK3BEWgMvCtes5"
        },], "الأذكار بعد كل صلاة":
        [{
            "text": `ويتم المئة بقوله:

لا إلهَ إِلاَّ اللَّهُ وَحْدَهُ لا شَرِيكَ لَهُ، لَهُ الملْكُ وَلَهُ الحَمْدُ وَهُوَ على كُلّ شَيْءٍ قَدِيرٌ.`,
            "source": "مسلم 597",
            "count": "1",
            "order": "1",
            "categoryId": "-MRLfvhyVGpue7aeUZ-y"
        }, {
            "text": `الله أكبر (ثلاثاً وثلاثين).`,
            "source": "مسلم 595",
            "count": "33",
            "order": "1",
            "categoryId": "-MRLfvhyVGpue7aeUZ-y"
        }, {
            "text": `الحمد لله (ثلاثاً وثلاثين).`,
            "source": "مسلم595",
            "count": "33",
            "order": "1",
            "categoryId": "-MRLfvhyVGpue7aeUZ-y"
        }, {
            "text": `سبحان الله (ثلاثاً وثلاثين).`,
            "source": "مسلم 595",
            "count": "33",
            "order": "1",
            "categoryId": "-MRLfvhyVGpue7aeUZ-y"
        }, {
            "text": `اللهمَّ لا مانعَ لما أعطيتَ ولا مُعطيَ لما منعتَ، ولا ينفعُ ذا الجدِّ منك الجَدُّ.`,
            "source": "مسلم 593",
            "count": "1",
            "order": "1",
            "categoryId": "-MRLfvhyVGpue7aeUZ-y"
        }, {
            "text": `اللَّهمَّ أنتَ السَّلامُ ومنكَ السَّلامُ، تبارَكْتَ يا ذا الجلالِ والإِكْرامِ.

اللَّهمَّ أعنِّي على ذِكْرِكَ، وشُكْرِكَ، وحُسنِ عبادتِكَ.`,
            "source": "أبو داود 1512-1522",
            "count": "1",
            "order": "1",
            "categoryId": "-MRLfvhyVGpue7aeUZ-y"
        }, {
            "text": `لا إلهَ إِلاَّ اللَّهُ وَحْدَهُ لا شَرِيكَ لَهُ، لَهُ الملْكُ وَلَهُ الحَمْدُ وَهُوَ على كُلّ شَيْءٍ قَدِيرٌ.

لا إلهَ إِلاَّ اللّه مُخْلِصِينَ لَهُ الدِّينَ وَلَوْ كَرِهَ الكافِرُونَ‏.`,
            "source": "مسلم 594",
            "count": "1",
            "order": "1",
            "categoryId": "-MRLfvhyVGpue7aeUZ-y"
        }, {
            "text": `استغفر الله (ثلاثاً).`,
            "source": "مسلم 591",
            "count": "3",
            "order": "1",
            "categoryId": "-MRLfvhyVGpue7aeUZ-y"
        },], "بعد صلاة الفجر":
        [{
            "text": `لا إِلهَ إِلاَّ اللَّهُ وَحْدَهُ لاَ شَرِيكَ لَهُ، لَهُ الملْكُ وَلَهُ الحَمْدُ يُحْيي وَيُمِيتُ وَهُوَ على كُلّ شَيْءٍ قَدِيرٌ (عشراً).`,
            "source": "الترمذي 3474. النسائي 9877",
            "count": "10",
            "order": "1",
            "categoryId": "-MRiuh5zsHmfw5P8bsy_"
        }, {
            "text": `اللَّهُمَّ أجِرْنِي مِنَ النَّار (سبعاً).`,
            "source": "النسائي 9859. أبو داود 5079. أحمد 18054",
            "count": "7",
            "order": "2",
            "categoryId": "-MRiuh5zsHmfw5P8bsy_"
        }, {
            "text": `اللَّهُمَّ إني أسألُكَ عِلْماً نافِعاً، وعَمَلاً مُتَقَبَّلاً، وَرِزْقاً طَيِّباً.`,
            "source": "ابن ماجة 925. أحمد 26602",
            "count": "1",
            "order": "3",
            "categoryId": "-MRiuh5zsHmfw5P8bsy_"
        }, {
            "text": `اللَّهُمَّ بِكَ أُحاوِلُ، وَبِكَ أُصَاوِلُ، وَبِكَ أُقاتِلُ.`,
            "source": "ابن حبان 2027. النووي في الأذكار 5/169",
            "count": "1",
            "order": "4",
            "categoryId": "-MRiuh5zsHmfw5P8bsy_"
        },], "بعد صلاة الضحى":
        [{
            "text": `اللَّهُمَّ رَبّ جِبْرِيلَ وَإِسْرَافِيلَ وَمِيكائِيلَ ومُحَمَّدٍ النَّبي صلى اللّه عليه وسلم، أعُوذُ بِكَ مِنَ النَّارِ (ثلاثاً).`,
            "source": "النووي في الأذكار 1/96",
            "count": "3",
            "order": "1",
            "categoryId": "-MRivaQknmFSsqhIoFXX"
        },], "بعد صلاة العصر":
        [{
            "text": `لا إِلهَ إِلاَّ اللَّهُ وَحْدَهُ لاَ شَرِيكَ لَهُ، لَهُ الملْكُ وَلَهُ الحَمْدُ يُحْيي وَيُمِيتُ وَهُوَ على كُلّ شَيْءٍ قَدِيرٌ (عشراً).`,
            "source": "النسائي 9877",
            "count": "10",
            "order": "1",
            "categoryId": "-MRiydro-kFv0V78NJkJ"
        },], "بعد صلاة المغرب":
        [{
            "text": `لا إِلهَ إِلاَّ اللَّهُ وَحْدَهُ لاَ شَرِيكَ لَهُ، لَهُ الملْكُ وَلَهُ الحَمْدُ يُحْيِي ويُمِيتُ وَهُوَ عَلى كُلِّ شَيْءٍ قَدِيرٌ (عشراً).`,
            "source": "الترمذي 3435.",
            "count": "10",
            "order": "1",
            "categoryId": "-MRiytSMMny3p4U_dKLj"
        }, {
            "text": `اللَّهُمَّ أجِرْنِي مِنَ النَّار (سبعاً).`,
            "source": "النسائي 9859. أبو داود 5079. أحمد 18054",
            "count": "7",
            "order": "2",
            "categoryId": "-MRiytSMMny3p4U_dKLj"
        }, {
            "text": `وبعد سنة المغرب يقول: يا مُقَلِّبَ القُلُوبِ وَالأَبْصَارِ ثَبِّتْ قُلُوبَنا على دِينِكَ.`,
            "source": "النووي في الأذكار 1/213",
            "count": "1",
            "order": "3",
            "categoryId": "-MRiytSMMny3p4U_dKLj"
        },], "بعد الوتر":
        [{
            "text": `اللَّهُمَّ إني أَعُوذُ بِرِضَاكَ مِنْ سَخَطِكَ، وأعُوذُ بِمُعافاتِكَ مِنْ عُقُوبَتِكَ، وأعُوذُ بِكَ لا أُحْصِي ثَناءً عَلَيْكَ أَنْتَ كما أثْنَيْتَ على نَفْسِك.`,
            "source": "أبو داود 1427. ابن ماجة 1179",
            "count": "1",
            "order": "1",
            "categoryId": "-MRizMRTwf5tGfMA3hpy"
        }, {
            "text": `سُبْحانَ المَلِكِ القُدُّوسِ (ثلاثاً). ويمدها بالثالثة.`,
            "source": "النسائي 1452",
            "count": "3",
            "order": "1",
            "categoryId": "-MRizMRTwf5tGfMA3hpy"
        },], "دعاء القنوت":
        [{
            "text": `اللَّهُمَّ اهْدِني فِيمَنْ هَدَيْتَ، وعَافِني فِيمَنْ عَافَيْتَ، وَتَوَلّني فِيمَن تَوَلَّيْتَ، وبَارِكْ لِي فِيما أَعْطَيْتَ، وَقِني شَرَّ ما قَضَيْتَ، فإنَّكَ تَقْضِي وَلا يُقْضَى عَلَيْكَ، وَإِنَّهُ لا يَذِلُّ مَنْ وَالَيْتَ وَلا يَعِزُّ مَن والَيْت، تَبَارَكْتَ رَبَّنا وَتَعالَيْتَ،
اللَّهُمَّ إنَّا نَسْتَعِينُكَ وَنَسْتَغْفِرُكَ وَلاَ نَكْفُرُكَ، وَنُؤْمِنُ بِكَ وَنَخْلَعُ مَنْ يَفْجُرُكَ، اللَّهُمَّ إيَّاكَ نَعْبُد، ولَكَ نُصَلِّي وَنَسْجُد، وَإِلَيْكَ نَسْعَى وَنحْفِدُ، نَرْجُو رَحْمَتَكَ وَنَخْشَى عَذَابَكَ، إنَّ عَذَابَكَ الجِدَّ بالكُفَّارِ مُلْحِقٌ‏.‏ اللَّهُمَّ اغْفِرْ للْمُؤْمِنِينَ وَالمؤْمِناتِ والمسْلِمِين والمُسْلِماتِ، وأصْلِح ذَاتَ بَيْنِهِمْ، وأَلِّفْ بَيْنَ قُلُوبِهِمْ، وَاجْعَلْ فِي قُلُوبِهِم الإِيمَانَ وَالحِكْمَةَ، وَثَبِّتْهُمْ على مِلَّةِ رَسُولِ اللَّهِ صلى اللّه عليه وآله وسلم، وَأَوْزِعْهُمْ أنْ يُوفُوا بِعَهْدِكَ الَّذي عاهَدْتَهُمْ عَلَيْهِ، وَانْصُرْهُمْ على عَدُّوَكَ وَعَدُوِّهِمْ إِلهَ الحَقّ وَاجْعَلْنا مِنْهُمْ.
`,
            "source": "الترمذي 464. البيهقي 3138. النووي في الأذكار 2/131",
            "count": "1",
            "order": "1",
            "categoryId": "-MRjR09x_rxqtpj9WT1D"
        },], "إذا خرج من المسجد":
        [{
            "text": `أعُوذُ باللّه العَظِيم، وَبِوَجهِهِ الكَريم، وسُلْطانِهِ القَديم، من الشِّيْطانِ الرَّجِيم، اللهمّ صلّ على محمد وعلى آل محمد، اللَّهُمَّ إني أسألُكَ مِنْ فَضْلِكَ. (ويقدم رجله اليسرى).`,
            "source": "مسلم 713. النووي في الأذكار ص84",
            "count": "1",
            "order": "1",
            "categoryId": "-MRkUe_W4albfFH5Syuh"
        },], "إذا دخل بيته":
        [{
            "text": `باسم اللّه. اللَّهُمَّ إِنِّي أسألُكَ خَيْرَ المَوْلِجِ وَخَيْرَ المَخْرَجِ، باسْمِ اللَّهِ وَلجْنا، وباسْمِ اللَّهِ خَرَجْنا، وَعَلى اللَّهِ رَبِّنا تَوَكَّلْنا. السلام عليكم ورحمة الله وبركاته.`,
            "source": "النووي في الأذكار ص72. أبو داود 5096",
            "count": "1",
            "order": "1",
            "categoryId": "-MRkUuWrSMfVFH85DR3K"
        }, {
            "text": `إذا دخل بيتاً غير مسكون:

بِسْمِ الله. السَّلامُ عَلَيْنا وعلى عِباد اللَّهِ الصَّالِحِين.`,
            "source": "النووي في الأذكار ص74",
            "count": "1",
            "order": "2",
            "categoryId": "-MRkUuWrSMfVFH85DR3K"
        },], "إذا قُدِّمَ له الطعام":
        [{
            "text": `اللَّهُمَّ بارِكْ لَنا فِيما رَزَقْتَنا، وَقِنا عَذَابَ النَّارِ.`,
            "source": "النووي في الأذكار 1/556",
            "count": "1",
            "order": "1",
            "categoryId": "-MRkVN4nqGYcITDAw-yS"
        }, {
            "text": `باسم اللَّهِ.

ويُستحبُّ أن يجهرَ بالتسمية ليكونَ فيه تنبيهٌ لغيره على التسمية وليُقتدى به في ذلك.`,
            "source": "البخاري 5376. النووي في الأذكار ص372",
            "count": "1",
            "order": "2",
            "categoryId": "-MRkVN4nqGYcITDAw-yS"
        }, {
            "text": `إن نَسِيَ أن يذكُر اسمَ الله تعالى في أوَّلهِ:

باسم اللَّهِ أوَّلَهُ وآخِرَهُ.`,
            "source": "الترمذي 1858. أبو داود 3767",
            "count": "1",
            "order": "3",
            "categoryId": "-MRkVN4nqGYcITDAw-yS"
        }, {
            "text": `وإذا سَقاهُ اللَّهُ لَبَناً:

اللَّهُمَّ بارِكْ لَنا فِيهِ وَزِدْنا مِنْهُ.`,
            "source": "الترمذي 3455. أبو داود 3730 ",
            "count": "1",
            "order": "4",
            "categoryId": "-MRkVN4nqGYcITDAw-yS"
        },], "إذا فرغ من الطعام":
        [{
            "text": `الحَمْدُ لِلَّهِ الَّذي أطْعَمَنَا وَسَقانا وَجَعَلَنا مُسْلِمِينَ.`,
            "source": "الترمذي 3457. أبو داود 3850. ابن ماجة 3283",
            "count": "1",
            "order": "1",
            "categoryId": "-MRkWSEyDryWTjK6_fVy"
        }, {
            "text": `الحمدُ للَّهِ حمدًا كثيرًا طيِّبًا مُبارَكًا فيهِ، غَيرَ مَكْفِيٍّ، ولا مُوَدَّعٍ، ولا مُسْتَغْنًى عنهُ ربَّنا.`,
            "source": "البخاري 5458. ابن ماجة 3284",
            "count": "1",
            "order": "2",
            "categoryId": "-MRkWSEyDryWTjK6_fVy"
        }, {
            "text": `الحَمْدُ لِلَّهِ الَّذِي كَفانا وأرْوَانا غَيْرَ مَكْفِيّ ولا مَكْفُورٍ`,
            "source": "البخاري 5459",
            "count": "1",
            "order": "3",
            "categoryId": "-MRkWSEyDryWTjK6_fVy"
        }, {
            "text": `إذا أنهى طعامه وغسل يده:

الحمدُ للَّهِ الَّذي يطعمُ ولا يُطعَمُ، ومنَّ علينا فَهَدانا وأطعمنا، وسقانا وَكُلَّ بلاءٍ حسنٍ أبلانا الحمدُ للَّهِ غيرِ مودعٍ ولا مُكافأ ولا مَكْفورٍ، ولا مُستغنًى عنهُ، الحمدُ للَّهِ الَّذي أطعمَنا منَ الطَّعامِ، وسقانا منَ الشَّرابِ، وَكَسانا منَ العُريِ، وَهَدانا منَ الضَّلالِ، وبصَّرَنا منَ العمَى، وفضَّلَنا علَى كثيرٍ ممَّن خلقَ تفضيلًا، الحمدُ للَّهِ ربِّ العالمينَ.`,
            "source": "النسائي 10060",
            "count": "1",
            "order": "4",
            "categoryId": "-MRkWSEyDryWTjK6_fVy"
        },], "إذا أراد النوم":
        [{
            "text": `يتوضأ وضوءه للصلاة ثم يضطجع ويبدأ بالذكر، وإذا أراد النوم ينام على شقه الأيمن.

باسْمِكَ رَبي وَضَعْتُ جَنْبِي وَبِكَ أرْفَعُهُ، إنْ أمْسَكْتَ نَفْسِي فَاغفِر لَها، وَإِنْ أرْسَلْتَها فاحْفَظْها بما تَحْفَظُ بِهِ عِبادَكَ الصَّالِحينَ.
`,
            "source": "البخاري 7393. مسلم 2714",
            "count": "1",
            "order": "1",
            "categoryId": "-MS2Pc0fNmjwSvM1u7lM"
        }, {
            "text": `اللَّهُمَّ أسْلَمْتُ نَفْسِي إِلَيْكَ، وَفَوَّضْتُ أمْرِي إِلَيْكَ، وَألجأْتُ ظَهْرِي إِلَيْكَ، رَغْبَةَ وَرَهْبَةً إِلَيْكَ، لا ملجأ وَلا مَنْجَى مِنْكَ إِلاَّ إِليْكَ، آمَنْتُ بِكِتابِكَ الَّذي أنْزَلْتَ، وَنَبِيِّكَ الَّذي أَرْسَلْتَ.`,
            "source": "البخاري 6313. مسلم 2710",
            "count": "1",
            "order": "2",
            "categoryId": "-MS2Pc0fNmjwSvM1u7lM"
        }, {
            "text": `باسْمِكَ اللَّهُمَّ أمُوتُ وأَحْيَا`,
            "source": "البخاري 6324",
            "count": "1",
            "order": "3",
            "categoryId": "-MS2Pc0fNmjwSvM1u7lM"
        }, {
            "text": `الله أكبر (أربعاً وثلاثون)

`,
            "source": "البخاري 6318",
            "count": "33",
            "order": "4",
            "categoryId": "-MS2Pc0fNmjwSvM1u7lM"
        }, {
            "text": `سبحان الله (ثلاثاً وثلاثون)

`,
            "source": "البخاري 6318",
            "count": "33",
            "order": "5",
            "categoryId": "-MS2Pc0fNmjwSvM1u7lM"
        }, {
            "text": `الحمد لله (ثلاثاً وثلاثون)
`,
            "source": "البخاري 6318",
            "count": "33",
            "order": "6",
            "categoryId": "-MS2Pc0fNmjwSvM1u7lM"
        }, {
            "text": `قراءة سورة الفاتحة.`,
            "source": "مسند البزار 7393. الشوكاني في تحفة الذاكرين حديث 126",
            "count": "1",
            "order": "7",
            "categoryId": "-MS2Pc0fNmjwSvM1u7lM"
        }, {
            "text": `قراءة آية الكرسي.`,
            "source": "البخاري 5010",
            "count": "1",
            "order": "8",
            "categoryId": "-MS2Pc0fNmjwSvM1u7lM"
        }, {
            "text": `قراءة الآيتان من آخر سورة البقرة: (آمَنَ الرّسولُ.. إلى نهاية السورة).`,
            "source": "البخاري 5040. مسلم 708",
            "count": "1",
            "order": "9",
            "categoryId": "-MS2Pc0fNmjwSvM1u7lM"
        }, {
            "text": `قراءة سورة الكافرون: (قُلْ يا أَيُّها الكافِرون...)`,
            "source": "أبو داود 5055. الترمذي 3403. النسائي 10568. أحمد 23807",
            "count": "1",
            "order": "10",
            "categoryId": "-MS2Pc0fNmjwSvM1u7lM"
        }, {
            "text": `ثم ينفخ نفخاً رقيقاً في يديه ويقرأ (قُلْ هُوَ الله أَحَد...) والمعوذتين، ثم يمسح ما يستطيع من جسده من أعلاه إلى أسفله (يفعلها ثلاثاً).

`,
            "source": "البخاري 5017. أبو داود 5056. النسائي 10556",
            "count": "3",
            "order": "11",
            "categoryId": "-MS2Pc0fNmjwSvM1u7lM"
        },], "إذا استيقظ من الليل":
        [{
            "text": `لا إلَهَ إلَّا اللَّهُ وَحْدَهُ لا شَرِيكَ لَهُ، لَهُ الملْكُ وَلَهُ الحَمْدُ وَهُوَ على كُل شَيْءٍ قَدِيرٌ. الحَمْدُ لِلَّهِ، وَسُبْحانَ اللَّهِ، وَلا إِلهَ إِلاَّ اللَّهُ، وَاللَّهُ أكْبَرُ، وَلا حَوْلَ ولا قُوَّةَ إلا باللَّهِ. اللَّهُمَّ اغْفرْ لي.

`,
            "source": "البخاري 1154",
            "count": "1",
            "order": "1",
            "categoryId": "-MS43lofc39hhdgMPCN_"
        }, {
            "text": `لا إِلهَ إِلاَّ اللَّهُ الوَاحِدُ القَهَّارُ رَبُّ السَّمَوَاتِ والأرْضِ وَما بَيْنَهُما العَزِيزُ الغَفَّارُ.

`,
            "source": "النسائي 10634. ابن السني 757",
            "count": "1",
            "order": "2",
            "categoryId": "-MS43lofc39hhdgMPCN_"
        }, {
            "text": `باسمِكَ اللَّهمَّ وضعتُ جنبي وبِك أرفعُه، إن أمسَكتَ نَفسي فارحَمها، وإن رددتَها فاحفَظها بما تحفظُ بهِ عبادَك الصَّالحين.

`,
            "source": "الترمذي 3401. ابن السني 567",
            "count": "1",
            "order": "3",
            "categoryId": "-MS43lofc39hhdgMPCN_"
        },], "إذا قلق في فراشه فلم ينم":
        [{
            "text": `اللَّهُمَّ غارَتِ النُّجُومُ وَهَدأتِ العُيُونُ وأنْتَ حَيٌّ قَيُّومٌ لا تَأخُذُكَ سِنَةٌ وَلاَ نَوْمٌ، يا حيُّ يا قَيُّومُ أَهْدِىءْ لَيْلي، وأنِمْ عَيْنِي.

`,
            "source": "النووي في الأذكار 1/256",
            "count": "1",
            "order": "1",
            "categoryId": "-MS44WyGDEcSaL-Av38-"
        },], "إذا كان يفزعُ في منامه ":
        [{
            "text": `أعُوذُ بِكَلِماتِ اللَّهِ التَّامَّةِ مِنْ غَضَبهِ وعِقابِهِ وَشَرِّ عِبادِهِ، وَمِنْ هَمَزَاتِ الشَّياطِينِ وأنْ يَحْضُرُونِ.

`,
            "source": "الترمذي 3528. أبو داود 3893",
            "count": "1",
            "order": "1",
            "categoryId": "-MS44gVikqVWG9kyASQy"
        },], "إذا رأى في منامه ما يكره":
        [{
            "text": `وَلْيَتَحَوَّلْ عَنْ جَنْبِهِ الَّذِي كانَ عَلَيْهِ.
`,
            "source": "مسلم 2262",
            "count": "1",
            "order": "1",
            "categoryId": "-MS457xELXioV4GEN540"
        }, {
            "text": `اللَّهمَّ إني أعُوذُ بِكَ مِنْ عَمَل الشَّيْطانِ وَسَيِّئاتِ الأحْلامِ.

`,
            "source": "النووي في الأذكار 5/264",
            "count": "1",
            "order": "1",
            "categoryId": "-MS457xELXioV4GEN540"
        }, {
            "text": `اللهم إني اسألك من خيرها، وأعوذ بك من شرها.`,
            "source": "ابن ماجه 3910",
            "count": "1",
            "order": "1",
            "categoryId": "-MS457xELXioV4GEN540"
        }, {
            "text": `يَنْفُثْ أو يتفل عَنْ شِمالِهِ (ثلاثاً) ثم يقول:
أعوذ بالله من الشيطان الرجيم (ثلاثاً).
`,
            "source": "مسلم 2262",
            "count": "1",
            "order": "1",
            "categoryId": "-MS457xELXioV4GEN540"
        },],
}