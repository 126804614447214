<template>
  <base-layout page-title="<span style='color:#e69e2c;'>اليسير</span><span style='font-size:12px;'> من </span><span style='color:#00b4d8;'>الزاد</span>">
    <div class="container">
      <ion-grid>
        <ion-row>
          <ion-col><button @click="openMorningModal" class="morning-btn">أذكار الصباح</button></ion-col>
          <ion-col><button @click="openEveningModal" class="evening-btn">أذكار المساء</button></ion-col>
        </ion-row>
      </ion-grid>
      <div @click="hideCat(catId)" class="cat-container" v-for="(cat,catId)  in viewPrayers" :key="catId">
        <span :class="['cat',{'edited':cat.edited}]">{{catId}}</span>

        <div :ref="catId+'-hide'" style="display:none;" class="prayer-container-container">
          <div :class="['prayer-container',{'prayer-done':prayer.count <1}]" v-for="(prayer,index) in cat" :key="index" @click.stop="prayerClicked(catId,index)">
            <!-- <h1>{{prayer.order}}</h1> -->
            <span :class="['prayer-span prayer-text',{'long':prayer.text.length > 60}] "> {{prayer.text}}</span>

            <button v-if="prayer.count < 1" @click.stop="restoreOnePrayer(catId,index)" class="restore-prayer-btn">أعد قراءة هذا الدعاء</button>
            <span v-if="prayer.count > 0" class="prayer-span prayer-count">{{prayer.count}}</span>
            <span v-if="showSource && prayer.count > 0" class="prayer-span prayer-source">{{prayer.source}}</span>
            <div class="prayer-divider"></div>
            <!-- <p>COUNT: {{prayer.count}}</p> -->

          </div>
        </div>
        <div :class="['divider',{'divider-changed':cat.edited}]"></div>
      </div>

    </div>
  </base-layout>
</template>

<script >
//import {} from '@ionic/vue';
import BaseLayout from "@/base/BaseLayout.vue";
import fullPrayers from "../prayers.js";
import {IonGrid,IonRow,IonCol} from "@ionic/vue";
export default {
  name: "methods",
  components: { BaseLayout,IonGrid,IonRow,IonCol },

  data() {
    return {
      prayersJson: "",
      prayers: {},
      viewPrayers: JSON.parse(JSON.stringify(fullPrayers.fullPrayers)),
      showSource: true,
      //generateId:uuidv4()
    };
  },
  mounted() {
    // this.viewPrayers = {...fullPrayers.fullPrayers}
  },
  methods: {
    hideCat(ref) {
      var el = this.$refs[ref + "-hide"];
      if (el.style.display === "none") {
        Object.keys(this.$refs).forEach((key) => {
          this.$refs[key].style.display = `none`;
        });
        el.style.display = "block";
        el.style.border = "1px solid var(--my-secondary)";
        el.style.borderRadius = "5px";
      } else {
        el.style.display = "none";
      }
    },
    prayerClicked(catId, index) {
      if (this.viewPrayers[catId][index].count > 0) this.viewPrayers[catId][index].count -= 1;
      this.viewPrayers[catId].edited = true;
    },
    restoreOnePrayer(catId, index) {
      const fullPrayersObject = JSON.parse(JSON.stringify(fullPrayers.fullPrayers));
      this.viewPrayers[catId][index].count = fullPrayersObject[catId][index].count;
      var error = 0;
      Object.keys(this.viewPrayers[catId]).forEach((key) => {
        // console.log("FIRST ", this.viewPrayers[catId][key].count);
        // console.log("SECOND ", fullPrayersObject[catId][key].count);
        try {
          if (this.viewPrayers[catId][key].count != fullPrayersObject[catId][key].count) error++;
        } catch (e) {
          console.log(e);
        }
      });
      console.log(error);
      if (error > 0) return;
      else {
        this.viewPrayers[catId].edited = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  ion-grid{
    ion-row{
      ion-col{
        button{
          width:90%;
          margin:0 auto 0 auto;
          border:none;
          background:none;
          font-family: "Almarai";
          padding:10px;
        }
        .morning-btn{
          border-bottom:1px solid var(--my-orange);
          color:var(--my-orange);
        }
        .evening-btn{
          border-bottom:1px solid var(--my-blue);
          color:var(--my-blue);
        }
      }
    }
  }
  .divider {
    border: none;
    width: 100%;
    margin: 0 auto 0 auto;
    height: 10px;
    border-top: 1px solid var(--my-secondary);
    margin-top: 10px;
  }
  .divider-changed {
    border-color: var(--my-blue);
  }
  .prayer-divider {
    border: none;
    width: 150px;
    margin: 0 auto 0 auto;
    height: 10px;
    border-top: 1px solid var(--my-secondary);
    margin-top: 5px;
  }
  .cat-container {
    //background-color: var(--my-card-background);
    text-align: right;

    padding: 10px;
    //border-bottom: 1px solid var(--my-gray);
    //border-radius: (5px);
    margin: 10px;
    .cat {
      font-family: "Almarai";
      color: var(--my-white);

      line-height: 30px;
    }
    .edited {
      color: var(--my-blue);
    }
    .prayer-container-container {
      z-index: 99;
      .prayer-done {
        * {
          color: var(--my-secondary);
        }
        .restore-prayer-btn {
          color: var(--my-blue);
          background: none;
          border: 1px solid var(--my-blue);
          border-radius: 5px;
        }
      }
      .prayer-container {
        //background: var(--my-card-background);
        margin: 10px;
        border-radius: (5px);
        padding: 10px;
        color: var(--my-white);
        font-family: "Kitab-Regular";
        text-align: justify;
        .restore-prayer-btn {
          width: 100%;
          font-family: "Almarai";
          padding: 8px;

          color: var(--my-orange);
          background: none;
          border: 1px solid var(--my-orange);
          border-radius: 5px;
        }
        .prayer-span {
          display: block;
        }
        .prayer-text {
          font-size: 18px;
        }
        .long {
          text-indent: 20px;
        }

        .prayer-source {
          text-align: right;
          font-family: "Tajawal";
          font-size: 12px;
          //background:var(--my-darker-black);
          display: block;
          color: var(--my-source);
          border-radius: 5px;
          margin-top: 5px;
          text-align: center;
          padding: 5px;
        }
        .prayer-count {
          font-family: "Tajawal";
          background: var(--my-darker-black);
          color: var(--my-orange);
          display: block;
          border-radius: 5px;
          font-weight: 700;
          text-align: center;
          padding: 5px;
        }
      }
    }
  }
}
</style>